import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function SystemTransition() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const { t } = useTranslation(["page"]);

  return (
    <div className="system-transition transition-section">
      {t(`v${ver}.SystemTransition.hook-line-1`)}
      <br />
      {t(`v${ver}.SystemTransition.hook-line-2`)}
    </div>
  );
}
