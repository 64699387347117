/*global fbq, gtag, twq, rdt,  TossPayments*/
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { createStore } from "state-pool";
import { isKorean } from "../../../utils";
// import StripeAlert from "../components/StripeAlert";

const store = createStore();
store.setState("openModal", false);

export default function WaitListSection() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const onClick = () => {
    // Facebook Pixel
    if (fbq) {
      fbq("track", "Lead");
    }

    // Reddit Pixel
    if (rdt) {
      rdt("track", "Purchase");
    }

    // Google Analytics
    if (gtag) {
      gtag("event", "begin_checkout");
    }

    // Twitter Pixel
    if (twq) {
      twq("event", "tw-oepi4-oepi5", {});
    }

    if (isKorean(BROWSERLANGUAGE)) {
      window.location.href = "https://forms.gle/vM3quoNZQkPA1Xts5";
    } else {
      window.location.href = "https://forms.gle/Pfv1YRkRpLfrhsZj6";
    }
  };

  return (
    <div className="waitlist-section page-section">
      <div id="waitlist-scrollview"></div>
      <div className="section-title">
        <div>
          {t(`v${ver}.WaitListSection.title-1`)}
          <br />
          {t(`v${ver}.WaitListSection.title-2`)}
          <br />
          {t(`v${ver}.WaitListSection.title-3`)}
          <br />
        </div>
      </div>
      <div className="section-subtitle">
        {t(`v${ver}.WaitListSection.subtitle`)}
      </div>
      <div className="learner-diagnosis">
        <div className="diagnosis-header">
          {t(`v${ver}.WaitListSection.diagnosis-title`)}
        </div>
        <div className="diagnosis-description-container">
          <div className="diagnosis-description">
            <img
              className="tick-image"
              alt="tick"
              src="images/tick-orange.png"
            ></img>
            <div className="description-text">
              {t(`v${ver}.WaitListSection.diagnosis-description-1`)}
            </div>
          </div>

          <div className="diagnosis-description">
            <img
              className="tick-image"
              alt="tick"
              src="images/tick-orange.png"
            ></img>
            <div className="description-text">
              {t(`v${ver}.WaitListSection.diagnosis-description-2`)}
            </div>
          </div>

          <div className="diagnosis-description">
            <img
              className="tick-image"
              alt="tick"
              src="images/tick-orange.png"
            ></img>
            <div className="description-text">
              {t(`v${ver}.WaitListSection.diagnosis-description-3`)}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        id="waitlist-button"
        className="waitlist-button"
      >
        {t(`v${ver}.WaitListSection.waitlist-btn`)}
      </button>
    </div>
  );
}
