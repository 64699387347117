import React from "react";
import "../../../styles/core.scss";
import "../StoryMode.scss";
import FadeInSection from "../../../components/FadeInEffect";

function BodySection({ t, ver, imagePath }) {
  const countEnglishUI = [1, 2, 3, 4];
  return (
    <div className="BodySection">
      <div className="ProblemSection align-center">
        <hr className="divider divider-white"></hr>
        <FadeInSection>
          <div className="heading-container">
            <div className="label-heading">
              {t(`v${ver}.StoryMode.label-problem`)}
            </div>

            <div className="heading">
              {t(`v${ver}.StoryMode.title-problem`)}
            </div>
          </div>
          <div className="page-section-full-width align-center"></div>
        </FadeInSection>
      </div>

      <div className="SolutionSection page-section-full-width">
        <FadeInSection>
          <div className="heading-container align-center">
            <div className="label-heading">
              {t(`v${ver}.StoryMode.label-solution`)}
            </div>
            <div className="title">
              {t(`v${ver}.StoryMode.title-solution-l1`)}
            </div>
            <div className="title">
              {t(`v${ver}.StoryMode.title-solution-l2`)}
            </div>
          </div>{" "}
        </FadeInSection>
      </div>

      <div className="HomeSection page-section-full-width">
        <FadeInSection>
          <div className="page-section-gutter section-black flex-responsive">
            <div className="image-container container">
              <img src={imagePath + "home_main.png"} alt="home" />
            </div>
            <div className="heading-container align-center text-right">
              <div className="label-heading">
                {t(`v${ver}.StoryMode.label-home`)}
              </div>
              <div className="heading">
                {t(`v${ver}.StoryMode.title-home-l1`)}
                <br />
                {t(`v${ver}.StoryMode.title-home-l2`)}
              </div>
              <div className="description text-body">
                {t(`v${ver}.StoryMode.desc-home-l1`)}
                <br />
                {t(`v${ver}.StoryMode.desc-home-l2`)}
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>

      <div className="MissionSection page-section-full-width">
        <FadeInSection>
          <div className="page-section-gutter section-black flex-responsive">
            <div className="heading-container align-center text-left">
              <div className="label-heading">
                {t(`v${ver}.StoryMode.label-mission`)}
              </div>
              <div className="heading">
                {t(`v${ver}.StoryMode.title-mission-l1`)}
                <br />
                {t(`v${ver}.StoryMode.title-mission-l2`)}
              </div>
              <div className="description text-body">
                {t(`v${ver}.StoryMode.desc-mission-l1`)}
                <br />
                {t(`v${ver}.StoryMode.desc-mission-l2`)}
              </div>
            </div>
            <div className="image-container flex-col">
              <div className="item-1 flex-row">
                <img
                  className="mission-1"
                  src={imagePath + "mission_main_desktop.png"}
                  alt="mission"
                />
              </div>
              {/* 
                        <span className="image-heading bold-text">
              {t(`v${ver}.StoryMode.label-missions`)}
            </span>
      
            <hr className="divider divider-orange" />
            <div className="item-1 flex-row">
              <img
                className="mission-1"
                src={imagePath + "mission_1.png"}
                alt="mission 1"
              />
              <button className="button-reward">
                <img src={imagePath + "common_moneybag.png"} alt="Moneybag" />
                $100
              </button>
            </div>
            <div className="item-2 flex-row">
              <img
                className="mission-2"
                src={imagePath + "mission_2.png"}
                alt="mission 2"
              />
              <button className="button-reward">
                <img src={imagePath + "common_moneybag.png"} alt="Moneybag" />
                $150
              </button>
            </div>
            <div className="item-3 flex-row">
              <img
                className="mission-3"
                src={imagePath + "mission_3.png"}
                alt="mission 3"
              />
              <button className="button-reward">
                <img src={imagePath + "common_moneybag.png"} alt="Moneybag" />
                $200
              </button>
            </div> */}
            </div>
          </div>
        </FadeInSection>
      </div>

      <div className="CharacterSection page-section-full-width">
        <FadeInSection>
          <div className="page-section-gutter section-black flex-responsive">
            <div className="image-container">
              <img
                className="desktop-only"
                src={imagePath + "characters_main_mobile.png"}
                alt="characters"
              />
              <img
                className="mobile-only"
                src={imagePath + "characters_main_mobile.png"}
                alt="characters"
              />
            </div>
            <div className="heading-container align-center text-right">
              <div className="label-heading">
                {t(`v${ver}.StoryMode.label-characters`)}
              </div>
              <div className="heading">
                {t(`v${ver}.StoryMode.title-characters-l1`)}
                <br />
                {t(`v${ver}.StoryMode.title-characters-l2`)}
              </div>
              <div className="description text-body">
                {t(`v${ver}.StoryMode.desc-characters-l1`)}
                <br />
                {t(`v${ver}.StoryMode.desc-characters-l2`)}
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>

      <div className="PlaceSection page-section-full-width">
        <FadeInSection>
          <div className="page-section-gutter section-black flex-responsive">
            <div className="heading-container align-center text-left">
              <div className="label-heading">
                {t(`v${ver}.StoryMode.label-places`)}
              </div>
              <div className="heading">
                {t(`v${ver}.StoryMode.title-places-l1`)}
                <br />
                {t(`v${ver}.StoryMode.title-places-l2`)}
              </div>
              <div className="description text-body">
                {t(`v${ver}.StoryMode.desc-places-l1`)}
                <br />
                {t(`v${ver}.StoryMode.desc-places-l2`)}
              </div>
            </div>

            <div className="image-container desktop-only">
              <img
                className="place"
                src={imagePath + "places_main_desktop.png"}
                alt="places"
              />
            </div>
            <div className="image-container mobile-only">
              <img
                className="place"
                src={imagePath + "places_main_mobile.png"}
                alt="places"
              />
            </div>
            {/* <div className="image-container-mobile flex-col mobile-only">
            <img
              className="mission-1"
              src={imagePath + "place_item1_mobile.png"}
              alt="StoryMode-1"
            />

            <img
              className="mission-2"
              src={imagePath + "place_item2_mobile.png"}
              alt="StoryMode-1"
            />

            <img
              className="mission-3"
              src={imagePath + "place_item3_mobile.png"}
              alt="StoryMode-1"
            />
          </div> */}
          </div>
        </FadeInSection>
      </div>

      <div className="FunSection page-section-full-width">
        <FadeInSection>
          <div className="page-section-gutter section-black flex-responsive">
            <div className="image-container flex-col desktop-only">
              <img src={imagePath + "fun_main.png"} alt="shop" />
            </div>

            <div className="image-container flex-col mobile-only">
              <img src={imagePath + "fun_main_mobile.png"} alt="shop" />
            </div>

            <div className="heading-container align-center text-right">
              <div className="label-heading">
                {t(`v${ver}.StoryMode.label-fun`)}
              </div>
              <div className="heading">
                {t(`v${ver}.StoryMode.title-fun-l1`)}
                <br />
                {t(`v${ver}.StoryMode.title-fun-l2`)}
              </div>
              <div className="description text-body">
                {t(`v${ver}.StoryMode.desc-fun-l1`)}
                <br />
                {t(`v${ver}.StoryMode.desc-fun-l2`)}
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>

      <div className="EnglishSection page-section-full-width">
        <FadeInSection>
          <div className="page-section-gutter flex-col">
            <div className="heading-container">
              <div className="title">
                {t(`v${ver}.StoryMode.label-english`)}
              </div>
              <div className="heading">
                {t(`v${ver}.StoryMode.title-english-l1`)}
                <br />
                {t(`v${ver}.StoryMode.title-english-l2`)}
              </div>
            </div>

            <div className="image-container align-center flex-responsive">
              <img
                className="main-desktop desktop-only"
                src={imagePath + "english_main_desktop.png"}
                alt="english"
              />

              <div className="mobile-only">
                {countEnglishUI.map((item) => {
                  return (
                    <div className="ui-container flex-col">
                      <span className="ui-label label-heading orange-text">
                        {t(`v${ver}.StoryMode.english-ui-label-${item}`)}
                      </span>
                      <span className="ui-desc heading">
                        {t(`v${ver}.StoryMode.english-ui-desc-${item}-l1`)}
                        <br />
                        {t(`v${ver}.StoryMode.english-ui-desc-${item}-l2`)}
                      </span>
                      <img
                        className="ui-image"
                        src={`${imagePath}/english_item${item}_mobile.png`}
                        alt="ui"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default BodySection;
