import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function IntroduceProductSection() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  const countries_1 = [
    { name: "us", languageKey: "language_english" },
    { name: "spain", languageKey: "language_spanish" },
    { name: "france", languageKey: "language_french" },
    { name: "germany", languageKey: "language_german" },
    { name: "japan", languageKey: "language_japanese" },
  ];

  const countries_2 = [
    { name: "china", languageKey: "language_chinese" },
    { name: "italy", languageKey: "language_italian" },
    { name: "portugal", languageKey: "language_portuguese" },
    { name: "russia", languageKey: "language_russian" },
    { name: "korea", languageKey: "language_korean" },
  ];

  const languageElements_1 = countries_1.map((country) => (
    <div key={country.name} className="language-container flex-column">
      <img
        className="flag"
        src={`images/newhome/flags/${country.name}.webp`}
        alt={`${country.name} flag`}
      />
      {t(`v${ver}.IntroduceProductSection.${country.languageKey}`)}
    </div>
  ));

  const languageElements_2 = countries_2.map((country) => (
    <div key={country.name} className="language-container flex-column">
      <img
        className="flag"
        src={`images/newhome/flags/${country.name}.webp`}
        alt={`${country.name} flag`}
      />
      {t(`v${ver}.IntroduceProductSection.${country.languageKey}`)}
    </div>
  ));

  return (
    <div className="IntroduceProductSection">
      <div className="section-content">
        <div className="section-title-large">
          {t(`v${ver}.IntroduceProductSection.title`)}
        </div>

        <div className="section-subtitle-large">
          {t(`v${ver}.IntroduceProductSection.subtitle`)}
        </div>

        <div className="mic-image">
          <img
            className="mic-illustration"
            src="images/newhome/mic_illustration.webp"
            alt="mic illustration"
          ></img>
        </div>

        <div className="supporting-languages">
          <div className="top-divider flex-row">
            <hr className="divider-line-grey"></hr>
            {t(`v${ver}.IntroduceProductSection.languages_count`)}
            <hr className="divider-line-grey"></hr>
          </div>

          <div className="flex-responsive-desktop">
            {languageElements_1}
            {languageElements_2}
          </div>

          <div className="flex-responsive-mobile">
            <div className="flex-row"> {languageElements_1}</div>
            <div className="flex-row"> {languageElements_2}</div>
          </div>

          <hr className="divider-line-grey"></hr>
        </div>
      </div>
    </div>
  );
}
