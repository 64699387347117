import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function ProductImageSection() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  return (
    <div className=" ProductImageSection">
      <div className="section-content">
        <div className="section-background">
          <img
            className="product-image-big"
            srcSet="images/newhome/product_image.webp"
          ></img>
          <img
            className="product-image-small"
            src="images/newhome/product_image_small.webp"
          ></img>
        </div>
      </div>
    </div>
  );
}
