import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { isKorean } from "../utils";
import i18next from "i18next";
import i18n from "../i18n";
import { useState, useEffect, useRef } from "react";
import WaitListSection from "../pages/OldHome/sections/WaitListSection";
import TopBarOld from "./OldHome/sections/TopBarOld";
import "./OldHome/OldHome.scss";

export default function AboutPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const lang = searchParams.get("lang");
  if (lang) {
    i18n.changeLanguage(lang);
  }
  const { t } = useTranslation(["page"]);

  return (
    <>
      <TopBarOld />
      <div className="AboutPage page-section">
        <div className="title">{t(`v${ver}.AboutPage.problem-title`)}</div>
        <div className="problem-section section-container">
          <div className="problem-image img-container">
            <img src="/images/aboutus-frustrated.png" width={138}></img>
          </div>
          <div className="problem-text body-text">
            <div className="problem-par1" style={{ fontStyle: "italic" }}>
              {t(`v${ver}.AboutPage.problem-par1`)}
            </div>
            <div className="problem-par2">
              {t(`v${ver}.AboutPage.problem-par2`)}
            </div>
            <div className="problem-par3">
              {t(`v${ver}.AboutPage.problem-par3`)}
            </div>
            <div className="problem-par4" style={{ fontWeight: 700 }}>
              {t(`v${ver}.AboutPage.problem-par4`)}
            </div>
          </div>
        </div>
        <hr className="divider"></hr>
        <div className="solution-section section-container">
          <div className="solution-image img-container">
            <img src="/images/aboutus-thinking.png" width={160}></img>
          </div>
          <div className="solution-text body-text">
            <div className="solution-par1">
              {t(`v${ver}.AboutPage.solution-par1`)}
            </div>
            <div className="problem-par2">
              {t(`v${ver}.AboutPage.solution-par2`)}
            </div>
          </div>
        </div>
        <hr className="divider"></hr>
        <div className="product-section section-container">
          <div className="product-image img-container">
            <img src="/images/aboutus-services.png" width={125}></img>
          </div>
          <div className="product-text body-text">
            <div className="product-par1">
              {t(`v${ver}.AboutPage.product-par1`)}
            </div>
            <div className="product-par1-">
              <ul>
                <li> {t(`v${ver}.AboutPage.product-par1-li1`)}</li>
                <li> {t(`v${ver}.AboutPage.product-par1-li2`)}</li>
                <li> {t(`v${ver}.AboutPage.product-par1-li3`)}</li>
                <li> {t(`v${ver}.AboutPage.product-par1-li4`)}</li>
              </ul>
            </div>
            <div className="problem-par2">
              {t(`v${ver}.AboutPage.product-par2`)}
            </div>
            <div className="product-par3">
              {t(`v${ver}.AboutPage.product-par3`)}
            </div>
            <div className="product-par4">
              {t(`v${ver}.AboutPage.product-par4`)}
            </div>
          </div>
        </div>
        <hr className="divider"></hr>
        <div className="conclude-section section-container">
          <div className="conclude-image img-container">
            <img src="/images/oldhome/logo_square.webp" width={138}></img>
          </div>
          <div className="conclude-text body-text">
            <div className="conclude-par1">
              <span> {t(`v${ver}.AboutPage.conclude-par1-1`)} </span>
              <span style={{ fontWeight: 700, fontStyle: "italic" }}>
                {" "}
                {t(`v${ver}.AboutPage.conclude-par1-2`)}{" "}
              </span>
            </div>
            <div className="conclude-par2">
              {t(`v${ver}.AboutPage.conclude-par2`)}
            </div>
          </div>
        </div>
        {/* <WaitListSection /> */}
      </div>
    </>
  );
}
