import { useSearchParams } from "react-router-dom";
// import i18n from "../i18n";
import HeadlineSection from "./sections/HeadlineSection";
import Benefit1Section from "./sections/Benefit1Section";
import Benefit2Section from "./sections/Benefit2Section";
import Benefit3Section from "./sections/Benefit3Section";
import ConversionSection from "./sections/ConversionSection";
import FooterSection from "./sections/FooterSection";
import IntroduceProductSection from "./sections/IntroduceProduct";
import ProblemSection2 from "./sections/ProblemSection2";
import ProductImageSection from "./sections/ProductImageSection";
import ResultSection from "./sections/ResultSection";
import TopBarNew from "./sections/TopBarNew";

import "./NewHome.scss";
import NotificationBar from "../NewHome/sections/NotificationBar";

export default function NewHome() {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");
  //   if (lang) {
  //     i18n.changeLanguage(lang);
  //   }
  const ver = searchParams.get("ver");
  return (
    <div className="page-content NewHome">
      {/* <TopBarNew /> */}
      {/* <NotificationBar /> */}
      <HeadlineSection />
      <ProductImageSection />
      <ProblemSection2 />
      <IntroduceProductSection />

      {/* <ProblemSection /> */}
      {/* <TruthSection /> */}
      {/* <SupportingHeadlineSection /> */}
      {/* <HeroImageSection /> */}
      {/* <InspirationSection /> */}
      {/* <InspirationImageSection /> */}
      {/* <PlanOverviewSection />
      <PlanOverviewImageSection /> */}
      {/* <ProductOverviewSection /> */}

      <Benefit1Section />
      <Benefit2Section />
      <Benefit3Section />
      {/* <TechnologySection /> */}
      <ResultSection />

      {/* <ResultImageSection /> */}
      {/* <TestimonialsSection /> */}
      <ConversionSection />
      <FooterSection />
    </div>
  );
}
