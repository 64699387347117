import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ResponsiveImage from "../../../components/ResponsiveImage";
import "../../NewHome/NewHome.scss";

export default function Benefit3Section() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  return (
    <div className=" Benefit3Section">
      <div className="section-content">
        <div className="section-title">
          {" "}
          {t(`v${ver}.Benefit3Section.title-line-1`)}
          <br />
          {t(`v${ver}.Benefit3Section.title-line-2`)}
        </div>
        <div className="section-subtitle">
          {t(`v${ver}.Benefit3Section.subtitle`)}
        </div>

        <div className="image-container">
          <ResponsiveImage
            largeImage="/images/newhome/benefit3_big.webp"
            smallImage="images/newhome/benefit3_small.webp"
            className="image"
          />
        </div>
      </div>
    </div>
  );
}
