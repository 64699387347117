/* global fbq, gtag */

import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function HeadlineSection() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  const trackConversion = () => {
    var url = "https://onelink.to/gpsmug";

    // Facebook Pixel
    if (fbq) {
      fbq("track", "Lead");
    }

    if (gtag) {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-334838074/IhkMCM_wrO4CELry1J8B",
        event_callback: callback,
      });
    } else {
      window.location = url;
    }
  };

  return (
    <div className="section-content HeadlineSection flex-column">
      <div className="logo-image">
        <img
          className="logo-image"
          src="images/newhome/logo.webp"
          alt="logo"
          loading="lazy"
        ></img>
      </div>

      <div className="section-title"> {t(`v${ver}.HeadLineSection.title`)}</div>
      <div className="section-subtitle">
        {t(`v${ver}.HeadLineSection.subtitle`)}
      </div>

      <div onClick={trackConversion}>
        <div className="button-download">Download AutoLang</div>
      </div>
    </div>
  );
}
