import { useSearchParams } from "react-router-dom";
import "../../App.css";
import Footer from "../../components/Footer";
import i18n from "../../i18n";
import "../OldHome/OldHome.scss";
import CompareSection from "./sections/CompareSection";
import FeatureSection from "./sections/FeatureSection";
import MainSection from "./sections/MainSection";
import NotificationBar from "./sections/NotificationBar";
import ProductSection from "./sections/ProductSection";
import PromiseSection from "./sections/PromiseSection";
import SubscribeSection from "./sections/SubscribeSection";
import SystemSection from "./sections/SystemSection";
import TopBar from "./sections/TopBarOld";
import UpgradeSection from "./sections/UpgradeSection";
import WaitListSection from "./sections/WaitListSection";
import WakeSection from "./sections/WakeSection";
import SystemTransition from "./sections/transitions/SystemTransition";
import TestimonialSection from "./sections/TestimonialSection";

export default function OldHomePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");
  if (lang) {
    i18n.changeLanguage(lang);
  }

  const ver = searchParams.get("ver");

  // DEFAULT VERSION
  return (
    <div className="OldHome">
      <TopBar />
      {/* <NotificationBar /> */}
      <MainSection />
      <TestimonialSection />
      <WakeSection />
      <ProductSection />
      <UpgradeSection />
      {/* <UpgradeTransition /> */}
      <CompareSection />
      <FeatureSection />
      <SystemTransition />
      <SystemSection />
      {/* <LanguageSection /> */}
      <PromiseSection />
      {/* <ActionSection */}
      {/* <WaitListSection /> */}
      {/* <SubscribeSection /> */}
      <Footer />
    </div>
  );
}
