import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { isKorean } from "../../../utils";

export default function PromiseSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const { t } = useTranslation(["page"]);

  // if (ver == "2") {
  //   return (
  //     <div className="promise-section page-section">
  //       <div className="section-title">{t(`v${ver}.PromiseSection.title`)}</div>
  //       <div className="promise-graph-part">
  //         <div className={ver === "2" ? "promise-title" : "title"}>
  //           {t(`v${ver}.PromiseSection.promise1-title`)}{" "}
  //         </div>
  //         <div className="subtitle">
  //           {t(`v${ver}.PromiseSection.promise1-desc`)}
  //         </div>
  //         {/* <div className="bar-row">
  //     <div className="full-bar"></div>
  //     <div className="bar-label">3 months</div>
  //   </div>
  //   <div>LangGPT</div>
  //   <div className="bar-row">
  //     <div className="duo-bar"></div>
  //     <div className="bar-label">6 months</div>
  //   </div>
  //   <div>Duolingo</div> */}
  //         <div className="center-col">
  //           <img src="images/duolingo_dead.png" width={60} height={60} />
  //           <div className="old-promise">
  //             {" "}
  //             {t(`v${ver}.PromiseSection.promise1-before`)}
  //           </div>
  //           <img src="images/down_double_arrow.png" width={36} height={36} />
  //           <div className="new-promise blue">
  //             {" "}
  //             {t(`v${ver}.PromiseSection.promise1-after`)}
  //           </div>
  //         </div>
  //         <div className="divider"></div>
  //         <div className={ver === "2" ? "promise-title" : "title"}>
  //           {t(`v${ver}.PromiseSection.promise2-title`)}
  //         </div>
  //         <div className="subtitle">
  //           {t(`v${ver}.PromiseSection.promise2-desc`)}
  //         </div>
  //         <div className="center-col">
  //           <img src="images/babbel_dead.png" width={60} height={60} />
  //           <div className="old-promise">
  //             {" "}
  //             {t(`v${ver}.PromiseSection.promise2-before`)}
  //           </div>
  //           <img src="images/down_double_arrow.png" width={36} height={36} />
  //           <div className="new-promise blue">
  //             {" "}
  //             {t(`v${ver}.PromiseSection.promise2-after`)}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );

  // }
  // return (
  //   <div className="promise-section page-section">
  //     <div className="text-container">
  //       <div className="text-content-1">
  //         {t(`v${ver}.PromiseSection.before-line-1`)}
  //         <br />
  //         {t(`v${ver}.PromiseSection.before-line-2`)}
  //         <br />
  //         {t(`v${ver}.PromiseSection.before-line-3`)}
  //         <span>&nbsp;</span>
  //         <span className="highlight style-bold-blue">
  //           {t(`v${ver}.PromiseSection.before-line-3-emphasis`)}
  //         </span>

  //         <hr className="blue-section-divider"></hr>
  //       </div>
  //       <div className="text-content-2">
  //         <span className="highlight style-bold-blue">
  //           {t(`v${ver}.PromiseSection.after-line-1`)}
  //         </span>
  //         <br />

  //         <div className="logo">
  //           {t(`v${ver}.PromiseSection.after-line-2`)}
  //           <br />
  //         </div>
  //         {/* {t(`v${ver}.PromiseSection.after-line-3`)} */}
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="promise-section page-section">
      <div className="subtitle style-subtitle-16">
        {t(`v${ver}.PromiseSection.subtitle`)}
      </div>
      <div className="section-title style-title-32">
        {t(`v${ver}.PromiseSection.title-line-1`)}
        <br />
        {t(`v${ver}.PromiseSection.title-line-2`)}
      </div>
      {isKorean(BROWSERLANGUAGE) ? (
        <div className="promise-comp-row">
          <img src="images/duolingo_bad_kor.png" />
          {/* <img src="images/vs.png" width={40} /> */}
          {/* <span className="vs" style={{ fontSize: 20, fontWeight: "bolder" }}>
            vs
          </span> */}
          <img src="images/oldhome/promise_autolang.webp" />
        </div>
      ) : (
        <div className="promise-comp-row">
          <img src="images/duolingo-bad.png" />
          {/* <img src="images/vs.png" width={40} /> */}
          {/* <span style={{ fontSize: 20, fontWeight: "bolder" }}>vs</span> */}
          <img src="images/oldhome/promise_autolang.webp" />
        </div>
      )}
    </div>
  );
}
