import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { isKorean } from "../../../utils";

export default function FeatureSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const { t } = useTranslation(["page"]);

  // Grammar, Translation Selector
  const [grammar, setGrammar] = useState(true);

  return (
    <div className="Feature-section page-section">
      <div className="Feature-background">
        <div className="Feature-title">
          {t(`v${ver}.FeatureSection.title-line-1`)}
          <br />
          {t(`v${ver}.FeatureSection.title-line-2`)}
        </div>
        <div className="Feature-subtitle">
          {t(`v${ver}.FeatureSection.subtitle-line-1`)}
          <br />
          {t(`v${ver}.FeatureSection.subtitle-line-2`)}
        </div>

        {isKorean(BROWSERLANGUAGE) ? (
          <div className="Feature-section-image-section">
            <div className="Feature-section-comp-row">
              <div className="grammar-button" onClick={() => setGrammar(true)}>
                {grammar ? (
                  <div className="underlined">
                    {t(`v${ver}.FeatureSection.feature-1`)}
                  </div>
                ) : (
                  <div>{t(`v${ver}.FeatureSection.feature-1`)}</div>
                )}
              </div>
              <div
                className="translation-button"
                onClick={() => setGrammar(false)}
              >
                {grammar ? (
                  <div>{t(`v${ver}.FeatureSection.feature-2`)}</div>
                ) : (
                  <div className="underlined">
                    {t(`v${ver}.FeatureSection.feature-2`)}
                  </div>
                )}
              </div>
            </div>
            <div className="Feature-section-comp-col">
              {grammar ? (
                <img src="images/correction-mockup-kor.png" width="280" />
              ) : (
                <img src="images/trans-mockup-kor.png" width="280" />
              )}
            </div>
          </div>
        ) : (
          <div className="Feature-section-image-section">
            <div className="Feature-section-comp-row">
              <div className="grammar-button" onClick={() => setGrammar(true)}>
                {grammar ? (
                  <div className="underlined">
                    {t(`v${ver}.FeatureSection.feature-1`)}
                  </div>
                ) : (
                  <div>{t(`v${ver}.FeatureSection.feature-1`)}</div>
                )}
              </div>
              <div
                className="translation-button"
                onClick={() => setGrammar(false)}
              >
                {grammar ? (
                  <div>{t(`v${ver}.FeatureSection.feature-2`)}</div>
                ) : (
                  <div className="underlined">
                    {t(`v${ver}.FeatureSection.feature-2`)}
                  </div>
                )}
              </div>
            </div>
            <div className="images">
              {grammar ? (
                <img src="images/oldhome/feature_error.webp" />
              ) : (
                <img src="images/oldhome/feature_translator.webp" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
