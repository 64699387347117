import { useSearchParams, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";
import "../../../styles/core.scss";

export default function TopBarNew() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(["page"]);
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;
  const location = useLocation();

  return (
    <div className="topbar">
      <div className="section-content">
        <div className="top-bar-container flex-row">
          <a href="/">
            <img className="image-logo" src="images/newhome/logo_text.webp" />
          </a>

          {BROWSERLANGUAGE === "ko" ? (
            <div className="link">
              <a className="no-decoration" href="/storymode">
                스토리 모드
              </a>
            </div>
          ) : null}
          {/* <div className="top-bar-image">
          <img src="images/pre-order.webp" alt="pre-order-icon"></img>
        </div> */}
          {/* <button
          className="pre-order-button"
          type="button"
          onClick={onPreOrderClick}
        >
          Last Pre-order
        </button> */}
          {/* <div className="pre-order-text">Last pre-order ></div> */}
        </div>
      </div>
    </div>
  );
}
