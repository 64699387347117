import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { isKorean } from "../../../utils";

import i18next from "i18next";

export default function SystemSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const [lang, setLang] = useState("es");
  const { t } = useTranslation(["page"]);

  useEffect(() => {
    if (isKorean(BROWSERLANGUAGE)) setLang("eng");
    else setLang("es");
  }, []);

  return (
    <>
      <div className="system-section page-section">
        {/* <div className="section-title">The LangGPT System</div> */}
        <div className="feature-container">
          <div className="numbering">1</div>
          <div className="sub-label">
            {t(`v${ver}.SystemSection.step1-theme`)}
          </div>

          <div className="system-section-title style-title-32 text-start">
            {t(`v${ver}.SystemSection.step1-title`)}
          </div>

          <div className="system-section-subtitle style-subtitle-16">
            {" "}
            <br /> {t(`v${ver}.SystemSection.step1-desc-line1`)} <br />{" "}
            {t(`v${ver}.SystemSection.step1-desc-line2`)}
          </div>

          <div className="language-title">
            {" "}
            {t(`v${ver}.SystemSection.step1-lang`)}
          </div>

          <div className="flag-container flex-col">
            {/* ONLY WHEN LANG IS KOREAN, SHOW ENGLISH UI */}

            <div className="flag-row flex-row">
              {isKorean(BROWSERLANGUAGE) ? (
                <div
                  className={
                    "lang-button pointer" + (lang === "eng" ? " selected" : "")
                  }
                  // onClick={() => setLang("eng")}
                >
                  <img src="images/english.png" alt="" width={30} height={30} />
                </div>
              ) : null}
              <div
                className={
                  "lang-button pointer" + (lang === "es" ? " selected" : "")
                }
                // onClick={() => setLang("es")}
              >
                <img
                  src="images/spain.png"
                  alt="spain flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "fr" ? " selected" : "")
                }
                // onClick={() => setLang("fr")}
              >
                <img
                  src="images/france.png"
                  alt="france flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "de" ? " selected" : "")
                }
                // onClick={() => setLang("de")}
              >
                <img
                  src="images/germany.png"
                  alt="germany flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "jp" ? " selected" : "")
                }
                // onClick={() => setLang("jp")}
              >
                <img
                  src="images/japan.png"
                  alt="japan flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "it" ? " selected" : "")
                }
                // onClick={() => setLang("it")}
              >
                <img
                  src="images/italy.png"
                  alt="italy flag"
                  width={30}
                  height={30}
                />
              </div>
            </div>
            <div className="flag-row flex-row">
              <div
                className={
                  "lang-button pointer" + (lang === "zh" ? " selected" : "")
                }
                // onClick={() => setLang("zh")}
              >
                <img
                  src="images/chinese.png"
                  alt="china flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "es" ? " selected" : "")
                }
                // onClick={() => setLang("es")}
              >
                <img
                  src="images/flags/russia.png"
                  alt="spain flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "es" ? " selected" : "")
                }
                // onClick={() => setLang("es")}
              >
                <img
                  src="images/flags/portugal.png"
                  alt="spain flag"
                  width={30}
                  height={30}
                />
              </div>
              <div
                className={
                  "lang-button pointer" + (lang === "es" ? " selected" : "")
                }
                // onClick={() => setLang("es")}
              >
                <img
                  src="images/flags/korea.png"
                  alt="spain flag"
                  width={30}
                  height={30}
                />
              </div>
            </div>
          </div>
          <div className="feature-image">
            <video width={280} autoPlay loop muted>
              <source src="images/oldhome/system_1.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="feature-container">
          <div className="numbering">2</div>
          <div className="sub-label">
            {t(`v${ver}.SystemSection.step2-theme`)}
          </div>
          <div className="system-section-title style-title-32 text-start">
            {t(`v${ver}.SystemSection.step2-title`)}
          </div>
          <div className="system-section-subtitle style-subtitle-16">
            {" "}
            <br /> {t(`v${ver}.SystemSection.step2-desc`)}
            <br />
            {t(`v${ver}.SystemSection.step2-desc-line2`)}
          </div>

          <div className="feature-image">
            {isKorean(BROWSERLANGUAGE) ? (
              <img
                src="images/oldhome/system_example.webp"
                alt=""
                width={268}
              />
            ) : (
              <img
                src="images/oldhome/system_example.webp"
                alt=""
                width={268}
              />
            )}
          </div>
        </div>
        <div className="feature-container">
          <div className="numbering">3</div>
          <div className="sub-label">
            {t(`v${ver}.SystemSection.step3-theme`)}
          </div>
          <div className="system-section-title style-title-32 text-start">
            {t(`v${ver}.SystemSection.step3-title`)}
          </div>
          <div className="system-section-subtitle style-subtitle-16">
            {" "}
            <br /> {t(`v${ver}.SystemSection.step3-desc`)}
            <br />
            {t(`v${ver}.SystemSection.step3-desc-line2`)}
          </div>

          <div className="feature-image">
            {isKorean(BROWSERLANGUAGE) ? (
              <img
                src="images/oldhome/system_flashcard.webp"
                alt="flashcard"
                width={268}
              />
            ) : (
              <img
                src="images/oldhome/system_flashcard.webp"
                alt="flashcard"
                width={268}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
