import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function UpgradeSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BcolumnSERLANGUAGE = i18next.language;

  const { t } = useTranslation(["page"]);

  // Grammar, Translation Selector
  const [grammar, setGrammar] = useState(true);

  return (
    <div>
      <div className="upgrade-section page-section">
        <div className="upgrade-content">
          <div className="title">
            {t(`v${ver}.UpgradeSection.title-line-1`)}
            <br />
            {t(`v${ver}.UpgradeSection.title-line-2`)}
          </div>

          {/* ChatGpt */}
          <div className="upgrade-section column-parent">
            <div className="upgrade-section column-comp">
              <div className="upgrade-name grid-parent">
                <img
                  className="comp-icon"
                  alt="chatgpt"
                  src="images/chatgpt-grey.png"
                ></img>
                {t(`v${ver}.UpgradeSection.upgrade1-name`)}
              </div>
              <div className="upgrade-adjective style-bold-blue">
                {t(`v${ver}.UpgradeSection.upgrade1-adjective`)}
              </div>
              <div className="upgrade-description">
                {t(`v${ver}.UpgradeSection.upgrade1-description`)}
              </div>
            </div>

            {/* Anki */}
            <div className="upgrade-section column-comp">
              <div className="upgrade-name grid-parent">
                <img
                  className="comp-icon"
                  alt="chatgpt"
                  src="images/anki-grey.png"
                ></img>
                {t(`v${ver}.UpgradeSection.upgrade2-name`)}
              </div>
              <div className="upgrade-adjective style-bold-blue">
                {t(`v${ver}.UpgradeSection.upgrade2-adjective`)}
              </div>
              <div className="upgrade-description">
                {t(`v${ver}.UpgradeSection.upgrade2-description`)}
              </div>
            </div>

            {/* DeepL */}

            <div className="upgrade-section column-comp">
              <div className="upgrade-name grid-parent">
                <img
                  className="comp-icon"
                  alt="chatgpt"
                  src="images/deepl-grey.png"
                ></img>
                {t(`v${ver}.UpgradeSection.upgrade3-name`)}
              </div>
              <div className="upgrade-adjective style-bold-blue">
                {t(`v${ver}.UpgradeSection.upgrade3-adjective`)}
              </div>
              <div className="upgrade-description">
                {t(`v${ver}.UpgradeSection.upgrade3-description`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
