import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function FooterSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const { t } = useTranslation(["page"]);

  const DownloadLinks = () => {
    return (
      <div className="download-link-container">
        <div className="download-images flex-row">
          <a href="https://apps.apple.com/us/app/autolang/id6449036780">
            <img
              className="download-image"
              src="images/newhome/download_apple.webp"
              alt="appstore link"
              loading="lazy"
            ></img>
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.apeiro.langgpt&hl=en-KR">
            <img
              className="download-image"
              src="images/newhome/download_google.webp"
              alt="playstore link"
              loading="lazy"
            ></img>
          </a>
        </div>
      </div>
    );
  };

  const SNSLinks = () => {
    return (
      <div className="sns-link-container flex-row">
        <a
          href="https://twitter.com/auto_lang"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/newhome/footer_x.png"
            alt="Twitter"
            className="sns-icon"
          />
        </a>
        <a
          href="https://www.instagram.com/autolang.byapeiro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/newhome/footer_instagram.png"
            alt="Instagram"
            className="sns-icon"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/apeirocorp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/newhome/footer_linkedin.png"
            alt="LinkedIn"
            className="sns-icon"
          />
        </a>
      </div>
    );
  };

  return (
    <div className="FooterSection">
      <div className="section-content">
        <div className="flex-column">
          <div className="contact-containers flex-row ">
            <div className="contact-container">
              <span className="contact-title">Inquiries via Email</span>
              <br />
              <span className="contact-link">support@apeirocorp.com</span>

              <br />
            </div>
            <div className="contact-container">
              <span className="contact-title">Join our Discord</span>
              <br />
              <a className="contact-link" href="https://discord.gg/sXhv2ZpchZ">
                Discord Channel
              </a>
              <br />
            </div>
          </div>
          <br /> <br />
          <div className="flex-row">
            <div className="company-info flex-row">
              <div className="info-name">© 2023 Apeiro Corp.</div>
              <a
                className="info-privacy"
                href="https://www.autolang.co/legal/eng/privacy.html"
              >
                Privacy Policy
              </a>
              <a
                className="info-terms"
                href="https://www.autolang.co/legal/eng/terms.html"
              >
                Terms of Service
              </a>
              <br />
            </div>
            <SNSLinks />
          </div>
        </div>
      </div>
    </div>
  );
}
