import React, { useState, useEffect } from "react";
import "../../../styles/core.scss";
import "../StoryMode.scss";
import { TypeAnimation } from "react-type-animation";

function HeadlineSection({ t, ver, imagePath }) {
  const headline = t(`v${ver}.StoryMode.headline`);
  const subtitle = t(`v${ver}.StoryMode.headline-subtitle`);

  // Delay for Subtitle Type Animation
  const [showSubtitle, setShowSubtitle] = useState(false);
  useEffect(() => {
    const delay = 2000;

    const timeoutId = setTimeout(() => {
      setShowSubtitle(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  });
  return (
    <div className="HeadlineSection">
      <div className="page-section-full-width">
        <div className="page-section-gutter">
          <div className="text-container text-left">
            <div className="headline">
              <TypeAnimation sequence={[headline, 1000]} />
            </div>
            {showSubtitle && (
              <div className="subtitle-headline">
                <TypeAnimation sequence={[subtitle, 1000]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeadlineSection;
