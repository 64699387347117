import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function ConversionSection() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  return (
    <div className="ConversionSection">
      <div className="section-content">
        <div className="section-title">
          {" "}
          {t(`v${ver}.ConversionSection.title-line-1`)}
          <br />
          {t(`v${ver}.ConversionSection.title-line-2`)}
        </div>
        {/* <div className="section-subtitle">
          {t(`v${ver}.ConversionSection.subtitle`)}
        </div> */}
        {/* <a href="https://apps.apple.com/us/app/autolang/id6449036780">
          <button className="button-conversion button-30">
            Download AutoLang{" "}
          </button>
        </a> */}
      </div>
    </div>
  );
}
