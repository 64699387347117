import React, { useEffect, useState } from "react";

const ResponsiveImage = ({ largeImage, smallImage, className }) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  return (
    <img
      className={className}
      src={viewportWidth >= 480 ? largeImage : smallImage}
      width="100%"
      alt=""
      loading="lazy"
    />
  );
};

export default ResponsiveImage;
