import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CountUp from "react-countup";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function ResultSection() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  return (
    <div className="ResultSection">
      <hr className="divider-line"></hr>
      <div className="section-content">
        <div className="section-title">
          {" "}
          {t(`v${ver}.ResultSection.title-line`)}
        </div>
        <div className="result-containers flex-responsive">
          <div className="flex-responsive">
            <div className="result-container flex-column">
              <div className="number flex-row">
                <div className="counter">
                  {" "}
                  <CountUp
                    end={2700}
                    duration={3}
                    enableScrollSpy
                    scrollSpyOnce={true}
                  />
                </div>
                <div className="number_info">+</div>
              </div>
              <div className="result-text">
                {t(`v${ver}.ResultSection.result-1`)}
              </div>
            </div>

            <div className="result-container flex-column">
              <div className="number flex-row">
                <div className="counter">
                  {" "}
                  <CountUp
                    end={18000}
                    duration={4.5}
                    enableScrollSpy
                    scrollSpyOnce={true}
                  />
                </div>
                <div className="number_info">+</div>
              </div>
              <div className="result-text">
                {t(`v${ver}.ResultSection.result-2`)}
              </div>
            </div>

            <div className="result-container flex-column">
              <div className="number flex-row">
                <div className="counter">
                  {" "}
                  <CountUp
                    end={160}
                    duration={3}
                    enableScrollSpy
                    scrollSpyOnce={true}
                  />
                </div>
                <div className="number_info smaller">(words per minute)</div>
              </div>
              <div className="result-text">
                {t(`v${ver}.ResultSection.result-3`)}
              </div>
            </div>

            <div className="result-container flex-column">
              <div className="number flex-row">
                <div className="counter">
                  {" "}
                  <CountUp
                    end={3200}
                    duration={4}
                    enableScrollSpy
                    scrollSpyOnce={true}
                  />
                </div>
                <div className="number_info">+</div>
              </div>
              <div className="result-text">
                {t(`v${ver}.ResultSection.result-4`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
