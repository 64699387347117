/* global fbq, gtag */

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isKorean } from "../../../utils";

export default function MainSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const waitlistScrollView = document.getElementById("waitlist-scrollview");
  const onDemoClick = () => {
    const element = document.getElementById("waitlist-scrollview");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }

    waitlistScrollView?.scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation(["page"]);
  const trackConversion = () => {
    var url = "https://onelink.to/gpsmug";
    // Facebook Pixel
    if (fbq) {
      fbq("track", "Lead");
    }

    if (gtag) {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-334838074/IhkMCM_wrO4CELry1J8B",
        event_callback: callback,
      });
    } else {
      window.location = url;
    }
  };

  return (
    <div className="main-section page-section">
      <div className="style-title-32">
        {t(`v${ver}.MainSection.title-line1`)}
        <br /> {t(`v${ver}.MainSection.title-line2`)}
      </div>
      <div className="section-subtitle">
        {t(`v${ver}.MainSection.subtitle`)}
        <br />
        {t(`v${ver}.MainSection.subtitle-line2`)}
      </div>
      {/* <div className="main-demo section-demo">
        <a href=" https://onelink.to/gpsmug" onClick={trackConversion}>
          <button className="main-demo button-demo" type="button">
            {t(`v${ver}.MainSection.action`)}
          </button>
        </a>
      </div> */}
      {isKorean(BROWSERLANGUAGE) ? (
        <img
          className="main-screen-img"
          src="images/product_image.gif"
          // main_screen_new
          width={300}
        />
      ) : (
        <img
          className="main-screen-img"
          src="images/product_image.gif"
          // main_screen_new
          width={320}
        />
      )}

      <div onClick={trackConversion}>
        <div className="button-download">Download AutoLang</div>
      </div>
    </div>
  );
}
