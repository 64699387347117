import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function TestimonialSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BcolumnSERLANGUAGE = i18next.language;

  const { t } = useTranslation(["page"]);

  const TestimonialContainer = ({ header, content, name, language }) => {
    return (
      <div className="testimonial-container flex-column">
        <div className="testimonial-rating flex-row">
          {[...Array(5)].map((_, index) => (
            <img
              key={index}
              src="images/oldhome/testimonial_star.png"
              alt="star"
              className="star"
            />
          ))}
        </div>
        <div className="testimonial-headline">{header}</div>
        <div className="testimonial-content">{content}</div>
        <div className="testimonial-name">{name}</div>
        <div className="testimonial-language">{language}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="TESTIMONIAL-SECTION page-section">
        <TestimonialContainer
          header={t(`v${ver}.TestimonialSection.testimonial-headline`)}
          content={t(`v${ver}.TestimonialSection.testimonial-content`)}
          name={t(`v${ver}.TestimonialSection.testimonial-name`)}
          language={t(`v${ver}.TestimonialSection.testimonial-language`)}
        />
      </div>
    </div>
  );
}
