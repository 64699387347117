import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function ProductSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const onPreOrderClick = () => {
    document.getElementById("pre-order").scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation(["page"]);
  return (
    <div className="product-section page-section ">
      <div className="section-title">{t(`v${ver}.ProductSection.title`)}</div>
      <div className="section-subtitle">
        {t(`v${ver}.ProductSection.subtitle-line1`)} <br />
        {t(`v${ver}.ProductSection.subtitle-line2`)}
      </div>
      <a
        className="demo-pre-order"
        href="https://www.youtube.com/watch?v=FfWydgBWPEE"
      >
        {t(`v${ver}.ProductSection.demo`)}
      </a>
      <div className="ui-container">
        <div className="row-parent">
          <div>
            <img
              className="ui-flashcard"
              src="images/oldhome/product_flashcard.webp"
              alt="flashcard ui"
            />
            <img
              className="ui-conversation"
              src="images/oldhome/product_chat.webp"
              alt="conversation ui"
            />
          </div>
        </div>

        <div className="ui-description">
          <div className="grid-parent">
            <div className="style-bold-darkgrey">
              {" "}
              {t(`v${ver}.ProductSection.feature-1-line-1`)}
            </div>
            <div className="style-bold-darkgrey">
              {" "}
              {t(`v${ver}.ProductSection.feature-1-line-2`)}
            </div>
            <div className="style-boldest">
              {" "}
              {t(`v${ver}.ProductSection.feature-2-line-1`)}
            </div>
            <div className="style-boldest">
              {" "}
              {t(`v${ver}.ProductSection.feature-2-line-2`)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="language-title">Currently offering 6 languages</div> */
}
{
  /* <div className="lang-row">
        <div
          className={"lang-button"}
        >
          <img src="spanish.png" width={30} height={30} />
        </div>
        <div
          className={"lang-button"}
        >
          <img src="french.png" width={30} height={30} />
        </div>
        <div
          className={"lang-button"}
        >
          <img src="german.png" width={30} height={30} />
        </div>
        <div
          className={"lang-button"}
        >
          <img src="japanese.png" width={30} height={30} />
        </div>
        <div
          className={"lang-button"}
        >
          <img src="italian.png" width={30} height={30} />
        </div>
        <div
          className={"lang-button"}
        >
          <img src="chinese.png" width={30} height={30} />
        </div>
      </div> */
}

function FeatureRow({ imageUrl, label, width = 40, height = 40 }) {
  return (
    <div className="feature-row">
      <div className="feature-image-box">
        <img src={imageUrl} height={height} width={width} />
      </div>
      <div>{label}</div>
    </div>
  );
}
