import React from "react";
import ReactDOM from "react-dom/client";
// import "./pages/OldHome/OldHome.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./i18n";
import AboutPage from "./pages/AboutPage";
import OldHomePage from "./pages/OldHome/OldHome";
import NewHomePage from "./pages/NewHome/NewHome";
import { useLocation } from "react-router-dom";
import StoryMode from "./pages/StoryMode/StoryMode";

const ABTestingPage = () => {
  const urlParams = new URLSearchParams(useLocation().search);
  const variant = urlParams.get("variant");

  // Variants :
  // jR9kLs3yXt : NewHomePage
  // pF7qGm2vZw : OldHomePage

  return variant === "pF7qGm2vZw" ? <OldHomePage /> : <NewHomePage />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <ABTestingPage />,
      },
      {
        path: "/home",
        element: <ABTestingPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "storymode",
        element: <StoryMode />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
