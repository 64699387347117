import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function ProblemSection2() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  return (
    <div className="ProblemSection2">
      <div className="section-content">
        <hr className="divider-line"></hr>
        <div className="section-title text-grey">
          {t(`v${ver}.ProblemSection2.title-line-1`)}
        </div>
        <div className="section-title">
          {t(`v${ver}.ProblemSection2.title-line-2`)}
        </div>

        <div className="section-subtitle">
          {" "}
          {t(`v${ver}.ProblemSection2.subtitle`)}
        </div>

        <div className="problem-image-small">
          <img
            className="image-small img"
            src="images/newhome/problem_steps.webp"
            alt="problem steps"
            loading="lazy"
          ></img>
        </div>
      </div>
    </div>
  );
}
