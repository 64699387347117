import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import "../../NewHome/NewHome.scss";

export default function Benefit1Section() {
  const { t } = useTranslation(["page"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const BROWSERLANGUAGE = i18next.language;
  const ver = searchParams.get("ver") || "1";

  return (
    <div className=" Benefit1Section flex-column">
      <div className="section-content">
        <div className="section-title">
          {" "}
          {t(`v${ver}.Benefit1Section.title-line-1`)}
          <br />
          {t(`v${ver}.Benefit1Section.title-line-2`)}
        </div>
        <div className="section-subtitle">
          {t(`v${ver}.Benefit1Section.subtitle`)}
        </div>

        <div className="section-background">
          <div className="flex-responsive">
            <img
              className="benefit1_1"
              src="/images/newhome/benefit1_1.webp"
              alt="app ui of benefit 1-1"
              loading="lazy"
            ></img>
            <img
              className="benefit1_2"
              src="/images/newhome/benefit1_2.webp"
              alt="app ui of benefit 1-2"
              loading="lazy"
            ></img>
            <img
              className="benefit1_3"
              src="/images/newhome/benefit1_3.webp"
              alt="app ui of benefit 1-3"
              loading="lazy"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
