import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function WakeSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";

  const { t } = useTranslation(["page"]);
  // DEFAULT LANDING PAGE

  return (
    <div className="wake-section page-section">
      <div className="wake-section-comp-col3-1">
        <div className="wake-label">{t(`v${ver}.WakeSection.label-new`)}</div>
        <div className="wake-title">
          {t(`v${ver}.WakeSection.title-new-line1`)}
          <br />
          {t(`v${ver}.WakeSection.title-new-line2`)}
          <br />
          {t(`v${ver}.WakeSection.title-new-line3`)}
          <br />
        </div>
        <img src="images/oldhome/wake_chatgpt.webp" width={120} />
      </div>
      <div className="wake-section-comp-col3-2">
        <div className="wake-label">{t(`v${ver}.WakeSection.label-old`)}</div>
        <div className="wake-title">
          {" "}
          {t(`v${ver}.WakeSection.title-old-line1`)}
          <br />
          {t(`v${ver}.WakeSection.title-old-line2`)}
          <br />
          {t(`v${ver}.WakeSection.title-old-line3`)}
          <br />
        </div>
        <img src="images/oldhome/wake_duolingo.webp" width={120} />
      </div>
    </div>
  );
}
