import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";

export default function Footer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;

  const { t } = useTranslation(["page"]);

  const SNSLinks = () => {
    return (
      <div className="sns-link-container flex-row">
        <a
          href="https://twitter.com/auto_lang"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/oldhome/footer_x.png"
            alt="Twitter"
            className="sns-icon"
          />
        </a>
        <a
          href="https://www.instagram.com/autolang.byapeiro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/oldhome/footer_instagram.png"
            alt="Instagram"
            className="sns-icon"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/apeirocorp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/oldhome/footer_linkedin.png"
            alt="LinkedIn"
            className="sns-icon"
          />
        </a>
      </div>
    );
  };

  return (
    <div className="footer">
      <div style={{ color: "black" }}>
        <b>{t(`v${ver}.Footer.refund-title`)}</b>
      </div>
      <div className="footer-section refund-desc">
        <b>{t(`v${ver}.Footer.refund-desc`)}</b>
        <br />
      </div>
      <br />

      <div className="company-sns-container flex-row">
        <div className="company-info flex-row">
          <div className="info-name">© 2023 Apeiro Corp.</div>
          <a
            className="info-privacy"
            href={
              BROWSERLANGUAGE === "ko"
                ? "https://www.autolang.co/legal/ko/privacy.html"
                : "https://www.autolang.co/legal/eng/privacy.html"
            }
          >
            {t(`v${ver}.Footer.privacy-policy`)}
          </a>
          <a
            className="info-terms"
            href={
              BROWSERLANGUAGE === "ko"
                ? "https://www.autolang.co/legal/ko/terms.html"
                : "https://www.autolang.co/legal/eng/terms.html"
            }
          >
            {t(`v${ver}.Footer.terms-of-service`)}
          </a>
        </div>
        <SNSLinks />
      </div>
    </div>
  );
}
