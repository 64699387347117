import React from "react";
import HeadlineSection from "./sections/HeadlineSection";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18n from "../../i18n";
import "./StoryMode.scss";
import TopBarNew from "../NewHome/sections/TopBarNew";
import BodySection from "./sections/BodySection";
import EndSection from "./sections/EndSection";
import FooterSection from "../NewHome/sections/FooterSection";

function StoryModePage() {
  const [searchParams, setSearchParams] = useSearchParams();

  // For Language Localizations
  const ver = searchParams.get("ver") || "1";
  const lang = searchParams.get("lang");
  if (lang) {
    i18n.changeLanguage(lang);
  }
  const { t } = useTranslation(["page"]);
  const imagePath = "../../../images/storymode/";

  return (
    <div className="StoryModePage">
      <TopBarNew />
      <HeadlineSection t={t} ver={ver} imagePath={imagePath} />
      <BodySection t={t} ver={ver} imagePath={imagePath} />
      <EndSection t={t} ver={ver} imagePath={imagePath} />
      <FooterSection />
    </div>
  );
}

export default StoryModePage;
