import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "../../OldHome/OldHome.scss";

export default function CompareSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";

  const { t } = useTranslation(["page"]);

  return (
    <div className="compare-section page-section">
      <div className="section-subtitle style-subtitle-16">
        {t(`v${ver}.CompareSection.subtitle`)}
      </div>

      <div className="style-title-32">{t(`v${ver}.CompareSection.title`)}</div>

      <div className="comparison-table">
        <div className="criteria-column">
          <div className="criterion">
            <img className="icon" src="images/chat_icon.png" />
            <div className="criterion-label">
              {t(`v${ver}.CompareSection.criterion-1`)}
            </div>
          </div>
          <div className="criterion">
            <img className="icon" src="images/mic.png" />
            <div className="criterion-label">
              {t(`v${ver}.CompareSection.criterion-2`)}
            </div>
          </div>
          <div className="criterion">
            <img className="icon" src="images/sound.png" />
            <div className="criterion-label">
              {t(`v${ver}.CompareSection.criterion-3`)}
            </div>
          </div>
          <div className="criterion">
            <img className="icon" src="images/translate.png" />
            <div className="criterion-label">
              {t(`v${ver}.CompareSection.criterion-4`)}
            </div>
          </div>
          <div className="criterion">
            <img className="icon" src="images/bookmark_icon.png" />
            <div className="criterion-label">
              {t(`v${ver}.CompareSection.criterion-5`)}
            </div>
          </div>
          <div className="criterion">
            <img className="icon" src="images/globe.png" />
            <div className="criterion-label">
              {t(`v${ver}.CompareSection.criterion-6`)}
            </div>
          </div>
        </div>
        <div className="product-column">
          <div
            className="chatgpt-label"
            style={{ fontSize: 16, fontWeight: 600 }}
          >
            ChatGPT
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/x-grey.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/x-grey.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/x-grey.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/x-grey.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/x-grey.png" />
          </div>
        </div>
        <div className="product-column">
          <div className="langgpt-label" style={{ fontSize: 16 }}>
            AutoLang
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
          <div className="icon-div">
            <img className="icon" src="images/check-blue.png" />
          </div>
        </div>
      </div>
    </div>
  );
}
