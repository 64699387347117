import React from "react";
import ResponsiveImage from "../../../components/ResponsiveImage";
import FadeInSection from "../../../components/FadeInEffect";

function EndSection({ t, ver, imagePath }) {
  const appDownloadLink = "https://onelink.to/gpsmug";
  return (
    <div className="EndSection">
      <FadeInSection>
        <div className="page-section-gutter flex-col">
          <div className="title">{t(`v${ver}.StoryMode.title-beta`)}</div>

          <div className="label text-body">
            {t(`v${ver}.StoryMode.label-beta`)}
          </div>

          <div className="image-container flex-col">
            <ResponsiveImage
              largeImage={imagePath + "beta_poster_desktop.png"}
              smallImage={imagePath + "beta_poster_mobile.png"}
              className={"poster"}
            />
            <div className="text-container align-left text-left flex-col">
              <div className="beta-info flex-row">
                <img
                  className="check"
                  src={imagePath + "icon_check.png"}
                  alt="check"
                />
                <div className="text-body">
                  {t(`v${ver}.StoryMode.benefit1-beta`)}
                </div>
              </div>
              <div className="beta-info flex-row align-left">
                <img
                  className="check"
                  src={imagePath + "icon_check.png"}
                  alt="check"
                />
                <div className="text-body">
                  {t(`v${ver}.StoryMode.benefit2-beta`)}
                </div>
              </div>
            </div>
            <a className="no-decoration" href={appDownloadLink}>
              <div className="button-cta label-heading">
                {t(`v${ver}.StoryMode.button-beta`)}
              </div>
            </a>
          </div>
        </div>
      </FadeInSection>
    </div>
  );
}

export default EndSection;
