import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useSearchParams } from "react-router-dom";

export default function TopBarOld() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ver = searchParams.get("ver") || "1";
  const BROWSERLANGUAGE = i18next.language;
  const location = useLocation();

  const onPreOrderClick = () => {
    document.getElementById("pre-order").scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation(["page"]);
  console.log(location.pathname);
  return (
    <div className="top-bar">
      <div className="top-bar-container">
        <a href="/">
          <img
            className="top-bar-logo"
            src="images/oldhome/logo_text_black.webp"
          />
        </a>
        <div className="links">
          {location.pathname == "/" ? null : (
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <span> {t(`v${ver}.TopBarOld.link-home`)}&nbsp;&nbsp;&nbsp;</span>
            </Link>
          )}
          <Link to="/about" style={{ textDecoration: "none", color: "black" }}>
            <span> {t(`v${ver}.TopBarOld.link-about`)}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
